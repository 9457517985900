import React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  display: none;
`

const SvgComponent = () => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg">
      <symbol id="svg-arrow-back" viewBox="0 0 13 10">
        <path
          d="M14 6.616a.5.5 0 0 0 0-1v1zm-13-.5l-.375-.33a.5.5 0 0 0 0 .66L1 6.117zm4.125 5.447a.5.5 0 0 0 .75-.66l-.75.66zm.75-10.233a.5.5 0 1 0-.75-.66l.75.66zM14 5.616H1v1h13v-1zm-8.125 5.286l-4.5-5.116-.75.66 4.5 5.117.75-.66zm-4.5-4.455l4.5-5.117-.75-.66-4.5 5.116.75.66z"
          fill="#919191"
        />
      </symbol>

      <symbol id="svg-arrow-global" viewBox="0 0 16 8">
        <path d="M.774.071l8.13 7.82-.775.744L0 .815.774.072z" />
        <path d="M16.366.816l-8.13 7.82-.773-.746L15.592.071l.774.745z" />
      </symbol>

      <symbol id="svg-list-check">
        <circle cx="15" cy="15" r="14.5" fill="#fff" stroke="#6EA668" />
        <path
          d="M13.75 21.25a.63.63 0 0 1-.39-.137l-6.25-5a.627.627 0 0 1 .39-1.114.63.63 0 0 1 .39.136l5.743 4.594 8.364-11.351a.624.624 0 0 1 1.122.277.619.619 0 0 1-.115.465l-8.75 11.875a.623.623 0 0 1-.504.254z"
          fill="#6EA668"
        />
      </symbol>

      <symbol id="svg-list-alert">
        <circle cx="15" cy="15" r="14.5" fill="#fff" stroke="#F26868" />
        <path
          d="M15 20c-.552 0-1-.392-1-.875V6.875c0-.483.448-.875 1-.875s1 .392 1 .875v12.25c0 .483-.448.875-1 .875zM15.018 23.999A1.011 1.011 0 0 1 14 23.032c-.005-.276.092-.53.276-.724a.98.98 0 0 1 .7-.308c.565.01 1.004.436 1.024.968.005.276-.092.53-.276.724a.986.986 0 0 1-.7.308l-.006-.001z"
          fill="#F26868"
        />
      </symbol>

      <symbol id="svg-list-pencil">
        <circle cx="20" cy="20" r="19.5" fill="#fff" stroke="#DDDCDC" />
        <path d="M22.923 13.505a.397.397 0 0 1 0 .561L12.81 24.18l-1.337 4.348 4.347-1.338 10.113-10.114a.397.397 0 1 1 .56.562L16.313 27.82a.398.398 0 0 1-.164.099l-5.158 1.587a.397.397 0 0 1-.496-.496l1.587-5.159a.397.397 0 0 1 .099-.163L22.36 13.505a.397.397 0 0 1 .562 0z" />
        <path d="M25.37 11.618l-2.447 2.448a.397.397 0 0 1-.562-.561l2.45-2.45a1.99 1.99 0 0 1 2.804 0l1.328 1.328a1.984 1.984 0 0 1 0 2.805l-2.45 2.45a.397.397 0 1 1-.56-.562l2.449-2.449a1.191 1.191 0 0 0 0-1.683l-1.326-1.326a1.197 1.197 0 0 0-1.685 0zM12.18 23.689a.397.397 0 0 1 .56 0l3.572 3.57a.397.397 0 0 1-.561.562L12.18 24.25a.397.397 0 0 1 0-.56z" />
        <path d="M23.552 12.314a.397.397 0 0 1 .561 0l3.571 3.572a.397.397 0 1 1-.561.561l-3.571-3.571a.397.397 0 0 1 0-.562z" />
      </symbol>

      <symbol id="svg-global-check" viewBox="0 0 22 18">
        <path
          d="M8.572 16.647l.002.002a1.715 1.715 0 0 0 2.393-.313L20.658 3.67l.002-.002c.266-.35.395-.801.318-1.264a1.665 1.665 0 0 0-.681-1.088l-.002-.001A1.705 1.705 0 0 0 19.306 1a1.7 1.7 0 0 0-1.352.665l-8.655 11.31-5.566-4.289-.002-.001a1.715 1.715 0 0 0-1.04-.352c-.496 0-.982.215-1.31.614-.605.73-.47 1.795.266 2.365l.002.002 6.923 5.333z"
          fill="#6EA668"
          stroke="#6EA668"
          strokeWidth="2"
        />
      </symbol>
    </Svg>
  )
}

export default SvgComponent
